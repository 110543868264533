<template>
  <b-container fluid>
    <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" header-title-class="w-100" header-class="justify-content-around">
      <template v-slot:headerTitle>
        <b-row id="customCalendarDropdown">
          <b-col md="3" sm="3">
            <b-button-group class="mr-2">
              <b-dropdown variant="light" :text="$t(header)">
                <b-dropdown-item to="/calendar/general"
                                 :active-class="setActive('calendar.general')">
                  <font-awesome-icon icon="fa-solid fa-calendar-days" /> &nbsp; {{$t('GeneralCalendar')}}
                </b-dropdown-item>
                <b-dropdown-item to="/calendar/staff/classic"
                                 :active-class="setActive('calendar.staffClassic')">
                  <font-awesome-icon icon="fa-sharp fa-regular fa-calendar-circle-user" /> &nbsp; {{$t('StaffCalendar')}}
                </b-dropdown-item>
                <b-dropdown-item to="/calendar/staff/grid"
                                 :active-class="setActive('calendar.staffGrid')">
                  <font-awesome-icon icon="fa-sharp fa-regular fa-calendar-circle-user" /> &nbsp; {{$t('StaffCalendar')}} ({{$t('Vertical')}})
                </b-dropdown-item>
                <b-dropdown-item to="/calendar/room/classic"
                                 :active-class="setActive('calendar.roomClassic')">
                  <font-awesome-icon icon="fa-sharp fa-regular fa-calendar-check" /> &nbsp; {{$t('RoomCalendar')}}
                </b-dropdown-item>
                <b-dropdown-item to="/calendar/room/grid"
                                 :active-class="setActive('calendar.roomGrid')">
                  <font-awesome-icon icon="fa-sharp fa-regular fa-calendar-check" /> &nbsp; {{$t('RoomCalendar')}} ({{$t('Vertical')}})
                </b-dropdown-item>
              </b-dropdown>
            </b-button-group>
          </b-col>
          <b-col md="4" sm="3">
            <date-picker style="width:80%" v-model="selectedDate" :tag-name="'calenderDate'" :is-required="false" />
          </b-col>
          <b-col md="5" sm="6" class="text-right">
            <b-button-group class="mr-2">
              <b-dropdown v-if="lastSelectedCalendar == 'appointmentCalendarGeneral'"
                          variant="light" :text="$t('GeneralCalenderType')">
                <b-dropdown-item @click="setGeneralCalenderType('dayGridMonth')"
                                 :active="setActiveGeneralCalenderType('dayGridMonth')">
                  <font-awesome-icon v-if="$store.getters['Calendar/generalCalendarType'] == 'dayGridMonth'"
                                     icon="fa-solid fa-check" />
                  &nbsp; {{$t('Monthly')}}
                </b-dropdown-item>
                <b-dropdown-item @click="setGeneralCalenderType('timeGridWeek')"
                                 :active="setActiveGeneralCalenderType('timeGridWeek')">
                  <font-awesome-icon v-if="$store.getters['Calendar/generalCalendarType'] == 'timeGridWeek'"
                                     icon="fa-solid fa-check" />
                  &nbsp; {{$t('Weekly')}}
                </b-dropdown-item>
                <b-dropdown-item @click="setGeneralCalenderType('timeGridDay')"
                                 :active="setActiveGeneralCalenderType('timeGridDay')">
                  <font-awesome-icon v-if="$store.getters['Calendar/generalCalendarType'] == 'timeGridDay'"
                                     icon="fa-solid fa-check" />
                  &nbsp; {{$t('Daily')}}
                </b-dropdown-item>
                <b-dropdown-item @click="setGeneralCalenderType('listWeek')"
                                 :active="setActiveGeneralCalenderType('listWeek')">
                  <font-awesome-icon v-if="$store.getters['Calendar/generalCalendarType'] == 'listWeek'"
                                     icon="fa-solid fa-check" />
                  &nbsp; {{$t('List')}}
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown variant="light" :text="$t('ColorSource')">
                <b-dropdown-item v-on:click="setColorType('appointmentStatus')"
                                 :active="setActiveForColor('appointmentStatus')">
                  <font-awesome-icon v-if="$store.getters['Calendar/calendarColorSource'] == 'appointmentStatus'"
                                     icon="fa-solid fa-check" />
                  &nbsp; {{$t('Status')}}
                </b-dropdown-item>
                <b-dropdown-item @click="setColorType('staff')"
                                 :active="setActiveForColor('staff')">
                  <font-awesome-icon v-if="$store.getters['Calendar/calendarColorSource'] == 'staff'"
                                     icon="fa-solid fa-check" />
                  &nbsp; {{$t('Staff')}}
                </b-dropdown-item>
                <b-dropdown-item @click="setColorType('service')"
                                 :active="setActiveForColor('service')">
                  <font-awesome-icon v-if="$store.getters['Calendar/calendarColorSource'] == 'service'"
                                     icon="fa-solid fa-check" />
                  &nbsp; {{$t('Service')}}
                </b-dropdown-item>
                <b-dropdown-item @click="setColorType('serviceGroup')"
                                 :active="setActiveForColor('serviceGroup')">
                  <font-awesome-icon v-if="$store.getters['Calendar/calendarColorSource'] == 'serviceGroup'"
                                     icon="fa-solid fa-check" />
                  &nbsp; {{$t('ServiceGroup')}}
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown variant="light" :text="$t('Actions')">
                <b-dropdown-item to="/appointment-new">{{$t('NewAppointment')}}</b-dropdown-item>
                <b-dropdown-item to="/appointment">{{$t('AppointmentList')}}</b-dropdown-item>
              </b-dropdown>
            </b-button-group>
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <router-view :key="$router.currentRoute.meta.childName" id="fullcalendar"></router-view>
      </template>
    </iq-card>
  </b-container>
</template>
<script>
  import DatePicker from '../../../components/shared/DatePicker'
  import { getToday } from '../../../utils/dateTimeExtensions'
  import '../../../plugins/vue-modal'

  export default {
    name: 'Calendars',
    components: {
      DatePicker
    },
    data() {
      return {
        selectedDate: getToday()
      }
    },
    methods: {
      openNewAppointmentPage() {
        this.$router.push({ name: 'appointment.new' });
      },
      goAppointmentList() {
        this.$router.push('/appointment');
      },
      setActive(route) {
        if (route == this.$route.name) {
          return 'active';
        }
        else {
          return '';
        }
      },
      setActiveForColor(type) {
        if (this.$store.getters['Calendar/calendarColorSource'] === type) {
          return true;
        }
        else {
          return false;
        }
      },
      setActiveGeneralCalenderType(type) {
        if (this.$store.getters['Calendar/generalCalendarType'] === type) {
          return true;
        }
        else {
          return false;
        }
      },
      setColorType(type) {
        this.$store.dispatch('Calendar/setCalendarColorSource', type)
      },
      setGeneralCalenderType(type) {
        this.$store.dispatch('Calendar/setGeneralCalendarType', type)
      }
    },
    watch: {
      selectedDate: function (val) {
        if (val) {
          this.$bus.$emit('CalendarSelectedDate', val);
        }
      }
    },
    computed: {
      header: function () {
        if (this.$route.name == 'calendar.general' || this.$route.name == 'calendar')
          return this.$t('GeneralCalendar');

        else if (this.$route.name == 'calendar.staffClassic')
          return this.$t('StaffCalendar');

        else if (this.$route.name == 'calendar.staffGrid')
          return `${this.$t('StaffCalendar')} ${this.$t('Vertical')}`;

        else if (this.$route.name == 'calendar.roomClassic')
          return this.$t('RoomCalendar');

        else if (this.$route.name == 'calendar.roomGrid')
          return `${this.$t('RoomCalendar')} ${this.$t('Vertical')}`;

        else
          return '';
      },
      lastSelectedCalendar: function () {
        return this.$store.getters['Calendar/lastSelectedCalendar'];
      }
    },
    mounted() {
      if (this.lastSelectedCalendar && this.lastSelectedCalendar != this.$route.name) {
        let routeName = this.$store.state.lastSelectedCalendar
        this.$router.push({ name: routeName });
      }
    }
  }
</script>

<style>
  .fc-content .fc-time {
    color: #ffffff !important;
  }

  .fc-content .fc-title {
    color: #ffffff !important;
  }

  #customCalendarDropdown .dropdown-item.active, .dropdown-item:active {
    color: #0a7389;
    text-decoration: none;
    background-color: #ebf4f8;
  }

  #customTextRight {
    text-align: right !important;
  }

  .sweet-title {
    margin-bottom: 10px !important;
    padding: 0.8em 0.5em 0 !important;
    font-size: 1.3em !important;
  }

  .sweet-btn {
    margin: 3px !important;
  }

  @media (max-width: 768px) {
    #customTextRight {
      text-align: left !important;
    }

    #customDateCenter {
      justify-content: left !important;
    }
  }

  .stickyFix {
    z-index: 90;
    top: 0px;
    left: 30px;
    right: 30px;
  }
</style>
