<template>
  <ValidationProvider :rules="isRequired ? 'required' : ''">
    <date-picker v-model="selectedDate"
                 format="DD.MM.YYYY"
                 valueType="DD.MM.YYYY"
                 class="col-md-12"
                 style="padding:0px !important;"
                 :clearable="true"
                 :lang="lang"
                 :placeholder="placeholderText"
                 :disabled-date="disabledBeforeOrAfterToday"
                 @input="selectedValues"
                 @input-error="inputError"
                 :inputClass="'form-control' + ((!isDateSelected) && isRequired ? ' is-invalid' : '')"
                 :inputAttr="{name: tagName}"
                 :disabled="disabled"
                 v-on="$listeners">

      <template #input="{ props, events }">
        <input type="text" v-bind="props" v-on="events" v-model="dateCopy" v-mask="'##.##.####'" />
      </template>
      <template #icon-calendar>
        <font-awesome-icon icon="fa-light fa-calendar" :hidden="!isDateSelected" />
      </template>

    </date-picker>
    <span class="invalid-feedback" v-if="!isDateSelected && isRequired">{{ $t("ValidationMessage.DateRequired") }}</span>
  </ValidationProvider>
</template>
<script>
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  export default {
    //props: ["value", "tagName", "isRequired", "maxDateToday"],
    name: "DatePickerLite",
    props: {
      value: String,
      tagName: String,
      isRequired: Boolean,
      maxDateToday: Boolean,
      minDateToday: Boolean,
      disabled: Boolean,
    },
    data() {
      return {
        //selectedDate: this.value,
        dateCopy: '',
        momentFormat: {
          // Date to String
          stringify: (date) => {
            //2020-09-10
            return date ? this.$moment(date).format('DD.MM.YYYY') : ''
          },
          // String to Date
          parse: (value) => {
            if (!value) return null;
            else {
              var turkishFormat = this.$moment(value, 'DD.MM.YYYY').toDate();
              var globalFormat = this.$moment(value).toDate();
              return value ? (globalFormat.toString() !== 'Invalid Date' ? globalFormat : turkishFormat) : null
            }
          }
        },
        lang: this.$store.getters['Auth/currentLanguage'],
        placeholderText: this.$t('DatePickerPlaceholderText')
      }
    },
    methods: {
      inputError: function (err) {
        this.$toastr.error(this.$t("ValidationMessage.InvalidDateError") + " : " + err);
      },
      selectedValues(event) {
        this.$emit('input', event);

      },
      disabledBeforeOrAfterToday(date) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (this.maxDateToday)
          return date > new Date(new Date().setHours(0, 0, 0, 0));
        else if (this.minDateToday)
          return date < new Date(new Date().setHours(0, 0, 0, 0));
        else
          return null;
      }
    },
    components: { DatePicker },
    computed: {
      isDateSelected: function () {
        if (this.selectedDate || this.value) { this.$emit("input", this.selectedDate); return true; }
        else { return false }
      },
      selectedDate: {
        get: function () {
          if (this.value && this.value.indexOf("T") != -1) {
            var splittedDateArray = this.value.split("T")[0].split("-");
            if (splittedDateArray.length > 0) {
              let newValue = splittedDateArray[2] + "." + splittedDateArray[1] + "." + splittedDateArray[0];
              this.dateCopy = newValue;
              return newValue;
            }
            else
              return "";
          }
          else {
            this.dateCopy = this.value;
            return this.value;
          }
        },
        set: function (newValue) {
          this.dateCopy = newValue;
          this.$emit('input', newValue);
          return newValue;
        }
      },
    },
    mounted: function () {
    }
  };
</script>

<style>
  .mx-datepicker {
    width: 100%;
  }
</style>
